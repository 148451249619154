import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import { FontStyle } from "../assets/styles";

const Card4 = ({ title, description, imageSrc }) => {
  const isMobile = useMediaQuery("(max-width: 600px)");

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      border: "#000000",
      padding: isMobile ? "10px" : "20px",
      width: isMobile ? "100%" : "500px",
      height: isMobile ? "auto" : "336px",
      background: "#FCFDFF",
      border: "1.22857px solid #DADADA",
      borderRadius: isMobile ? "20px" : "50px",
      marginBottom: isMobile ? "16px" : "0",
    },
    imageContainer: {
      display: "flex",
      justifyContent: "center",
      marginBottom: "15px",
    },
    image: {
      width: isMobile ? "32px" : "57px",
      height: isMobile ? "32px" : "57px",
    },
    title: {
      ...FontStyle.body21,
    },
    description: {
      display: "flex",
      textAlign: "center",
      ...FontStyle.body52,
    },
  };

  return (
    <Box sx={styles.container}>
      {imageSrc && (
        <Box sx={styles.imageContainer}>
          <img src={imageSrc} alt="Icon" style={styles.image} />
        </Box>
      )}
      <Box sx={styles.title}>{title}</Box>
      <Box sx={styles.description}>{description}</Box>
    </Box>
  );
};

export default Card4;