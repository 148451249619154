import React from 'react';
import { ThemeProvider, Typography, createTheme } from '@mui/material';
import { FontStyle } from '../assets/styles';

const TextColor = ({ text, highlightWords, highlightColor, defaultColor, styleType }) => {
  const appliedStyle = FontStyle[styleType]; 


  const words = text.split(/(\s+|,|\.|;|:|\!|\?)/).filter(Boolean);

  return (
      <Typography 
       
        component="span" 
        sx={{
          ...appliedStyle,
          color: defaultColor,
        }}
      >
        {words.map((word, index) => {
          const isHighlight = highlightWords.some(highlightWord => word.includes(highlightWord));
          return (
            <Typography
             
              component="span"
              key={index}
              sx={{
                ...appliedStyle,
               
                color: isHighlight ? highlightColor : defaultColor,
                 // Ensures child Typography inherits the parent's lineHeight
              }}
            >
              {word}
            </Typography>
          );
        })}
      </Typography>
   
  );
};

export default TextColor;
