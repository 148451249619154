import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    phoneNumber: "",
    traceId: "",
    userId: "",
    txnId: "",
  },
  reducers: {
    setPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
    setTraceId: (state, action) => {
      state.traceId = action.payload;
    },
    setUser_id: (state, action) => {
      state.userId = action.payload;
    },
    settxn_id: (state, action) => {
      state.txnId = action.payload;
    },
  },
});

export const { setPhoneNumber, setTraceId, setUser_id, settxn_id } =
  userSlice.actions;

export default userSlice.reducer;
