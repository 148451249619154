import React from "react";
import { Typography } from "@mui/material";
import styled from "styled-components";

function PrivacyPolicy() {
  const fontSize = 12; // Base font size in pixels
  const headingSize = fontSize * 1.6; // Heading size (1.6 times the font size)

  const Container = styled.div`
    display: flex;
    height: calc(
      100vh - 120px - 1rem
    ); /* Adjust height to account for the NavBar height */
    padding: 4rem;
    box-sizing: border-box;
    background-color: #ffffff; /* Set background color for the container */
    flex-direction: column; /* Stack sections vertically */
    overflow-y: auto; /* Enable vertical scrolling */

    /* WebKit scrollbar styles */
    ::-webkit-scrollbar {
      width: 1px; /* Width of the scrollbar */
    }

    ::-webkit-scrollbar-thumb {
      background-color: #888; /* Color of the scrollbar thumb */
      border-radius: 2px; /* Round the corners of the scrollbar thumb */
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: #555; /* Color of the scrollbar thumb on hover */
    }

    ::-webkit-scrollbar-track {
      background-color: #f1f1f1; /* Background color of the scrollbar track */
      border-radius: 4px; /* Round the corners of the scrollbar track */
    }
  `;

  return (
    <Container>
      <Typography
        variant="h1"
        style={{ fontSize: headingSize, marginBottom: "20px" }}
      >
        Privacy Policy
      </Typography>

      <Typography
        variant="h2"
        style={{ fontSize: headingSize, marginBottom: "10px" }}
      >
        Purpose and Scope
      </Typography>
      <Typography style={{ fontSize: fontSize, lineHeight: "1.6" }}>
        Svasth QRify is sensitive to privacy issues on the internet and
        generally has no objection to users accessing its website without
        providing any personal details. However, there are circumstances like
        those mentioned elsewhere in this policy when Svasth QRify (We) may
        request you to provide personal information. The purpose of this policy
        document is to highlight the scope, our obligations and
        responsibilities, and your rights under the laws and regulations
        governing privacy, information, and your consent to the collection and
        use of information by us.
      </Typography>

      <Typography
        variant="h2"
        style={{
          fontSize: headingSize,
          marginBottom: "10px",
          marginTop: "20px",
        }}
      >
        General Principles for the Processing of Personal Data
      </Typography>
      <Typography style={{ fontSize: fontSize, lineHeight: "1.6" }}>
        For the purpose of this document, “Personal Data” may be defined as any
        information that can be used to identify an individual or a set of
        information from which an individual can be identified, or a combination
        of a set of information which Svasth QRify collects, possesses, and
        retains in electronic form or otherwise. While you may browse our
        website without providing any specific information about yourself,
        certain data is gathered by default, like:
      </Typography>
      <ul>
        <li>
          <Typography style={{ fontSize: fontSize }}>
            Information you provide while registering or requesting contact from
            us, such as your name, email ID, phone number, company, designation,
            country, etc.
          </Typography>
        </li>
        <li>
          <Typography style={{ fontSize: fontSize }}>
            Information based on browsing, like your Internet Protocol address,
            your computer system, browser details, and all and any such
            information we may collect through cookies.
          </Typography>
        </li>
      </ul>

      <Typography
        variant="h2"
        style={{
          fontSize: headingSize,
          marginBottom: "10px",
          marginTop: "20px",
        }}
      >
        Cookies
      </Typography>
      <Typography style={{ fontSize: fontSize, lineHeight: "1.6" }}>
        A cookie is a small piece of text sent to your browser by a website you
        visit. It helps the website to remember information about your visit,
        like your preferences and other settings. This can make your next visit
        easier and the site more useful to you. Cookies play an important role.
        Without them, using the web browser would be a much more frustrating
        experience.
      </Typography>
      <Typography style={{ fontSize: fontSize, lineHeight: "1.6" }}>
        When you visit our website, a small piece of code is added to your
        browser. This code analyzes your behavior on the website. For example,
        it helps us to know which pages you visited and how much time you spent
        on a specific page. These insights into your behavior help us improve
        the user experience. Any details regarding cookies are provided in the
        Cookie Policy.
      </Typography>

      <Typography
        variant="h2"
        style={{
          fontSize: headingSize,
          marginBottom: "10px",
          marginTop: "20px",
        }}
      >
        Types of Information We Collect
      </Typography>
      <Typography style={{ fontSize: fontSize, lineHeight: "1.6" }}>
        We may collect general information about you, including your name, email
        address, phone number, residence and office address, designation,
        country, etc. In some cases, you may also be requested to provide
        certain information that is categorized as special under some law or
        regulation. For the collection, access, retention, and use of your
        information, you must mandatorily provide your consent. The consent form
        can be accessed from here.
      </Typography>
      <Typography style={{ fontSize: fontSize, lineHeight: "1.6" }}>
        You may want to remember that all your consent is voluntary and without
        any coercion, and you have the right to withdraw the consent at any
        given time. While such withdrawal will not deny you access to our
        website, nor will there be any adverse consequences, it may, in some
        cases, restrict you from availing certain services or using certain
        tools or systems for which the information is necessary.
      </Typography>

      <Typography
        variant="h2"
        style={{
          fontSize: headingSize,
          marginBottom: "10px",
          marginTop: "20px",
        }}
      >
        Use of Your Information
      </Typography>
      <Typography style={{ fontSize: fontSize, lineHeight: "1.6" }}>
        Your personal information may be used for:
      </Typography>
      <ul>
        <li>
          <Typography style={{ fontSize: fontSize }}>
            Creating your ID
          </Typography>
        </li>
        <li>
          <Typography style={{ fontSize: fontSize }}>
            Contacting you and responding to your queries and requests
          </Typography>
        </li>
        <li>
          <Typography style={{ fontSize: fontSize }}>
            Understanding your browsing behavior to improve user experience
          </Typography>
        </li>
        <li>
          <Typography style={{ fontSize: fontSize }}>
            Troubleshooting and site maintenance, events, etc.
          </Typography>
        </li>
        <li>
          <Typography style={{ fontSize: fontSize }}>
            Informing you about various updates, offers
          </Typography>
        </li>
        <li>
          <Typography style={{ fontSize: fontSize }}>
            Preventing, detecting, and fighting fraud or other illegal or
            unauthorized activities
          </Typography>
        </li>
        <li>
          <Typography style={{ fontSize: fontSize }}>
            Ensuring legal compliance
          </Typography>
        </li>
        <li>
          <Typography style={{ fontSize: fontSize }}>
            Any other purpose for which law does not specifically prohibit the
            use
          </Typography>
        </li>
      </ul>
      <Typography style={{ fontSize: fontSize, lineHeight: "1.6" }}>
        We may use the following means to collect data:
      </Typography>
      <ul>
        <li>
          <Typography style={{ fontSize: fontSize }}>
            Passive collection
          </Typography>
        </li>
        <li>
          <Typography style={{ fontSize: fontSize }}>
            Tracking technologies
          </Typography>
        </li>
        <li>
          <Typography style={{ fontSize: fontSize }}>
            Search engine marketing
          </Typography>
        </li>
        <li>
          <Typography style={{ fontSize: fontSize }}>
            Online advertising, including targeted search
          </Typography>
        </li>
      </ul>
      <Typography style={{ fontSize: fontSize, lineHeight: "1.6" }}>
        Your consent will allow us to use any of the above means to collect data
        unless it is expressly prohibited under any law or regulation.
      </Typography>

      <Typography
        variant="h2"
        style={{
          fontSize: headingSize,
          marginBottom: "10px",
          marginTop: "20px",
        }}
      >
        Data Security and Retention
      </Typography>
      <Typography style={{ fontSize: fontSize, lineHeight: "1.6" }}>
        We store all user information securely, protected via a variety of
        firewalls and access controls. We also have protocols, controls, and
        guidelines that govern data security, and they are periodically tested.
        We do not share personally identifiable information with third parties
        unless forced by law or it is necessary to fulfill a transaction you
        have requested or unless you otherwise give permission to us to do so.
        We may also share such information in response to a legal process, such
        as a court order or subpoena.
      </Typography>
      <Typography style={{ fontSize: fontSize, lineHeight: "1.6" }}>
        However, it may be pertinent to note that data flows over the internet
        with weak security, and it may be accessed and used by people who are
        not authorized by us or you.
      </Typography>
      <Typography style={{ fontSize: fontSize, lineHeight: "1.6" }}>
        We will retain the data as long as we deem it necessary. Even if you are
        not using our services anymore or have terminated the registration or
        have become dormant, the data we had collected will remain in our system
        for as long as we want. However, if you have provided any special
        category data, it can be erased at your request any time. In case you
        want to change, correct, or erase data, you may contact us.
      </Typography>

      <Typography
        variant="h2"
        style={{
          fontSize: headingSize,
          marginBottom: "10px",
          marginTop: "20px",
        }}
      >
        Your Rights for the Use of Data by Us
      </Typography>
      <Typography style={{ fontSize: fontSize, lineHeight: "1.6" }}>
        You have the right to:
      </Typography>
      <ul>
        <li>
          <Typography style={{ fontSize: fontSize }}>
            Request access to your personal data we hold about you. This
            includes the right to know whether we hold any information about you
            and, if we do, request access and a copy of such information.
          </Typography>
        </li>
        <li>
          <Typography style={{ fontSize: fontSize }}>
            Rectify your data, which entitles you to correct, complete, and add
            any information we may have on you.
          </Typography>
        </li>
        <li>
          <Typography style={{ fontSize: fontSize }}>
            Object to the use and processing of personal data, which may
            restrict you from using certain tools and systems.
          </Typography>
        </li>
        <li>
          <Typography style={{ fontSize: fontSize }}>
            Restrict the use of your data to a limited purpose.
          </Typography>
        </li>
        <li>
          <Typography style={{ fontSize: fontSize }}>
            Port your data, including requesting a copy of the data we hold and
            transferring it to any other data controller authorized by you.
          </Typography>
        </li>
      </ul>
      <Typography style={{ fontSize: fontSize, lineHeight: "1.6" }}>
        Despite our best efforts, should you feel aggrieved with respect to any
        data misuse, abuse, or wrongful use, we welcome you to contact us for a
        speedy and just resolution. You may contact:
      </Typography>
      <Typography style={{ fontSize: fontSize, lineHeight: "1.6" }}>
        Chief Data Protection Officer,
      </Typography>

      <Typography
        variant="h2"
        style={{
          fontSize: headingSize,
          marginBottom: "10px",
          marginTop: "20px",
        }}
      >
        Links to Other Websites and Applications
      </Typography>
      <Typography style={{ fontSize: fontSize, lineHeight: "1.6" }}>
        Through the use of our website or platform, you may access other
        websites. In such cases, we are not responsible for the security,
        privacy policy, or content of such third-party websites.
      </Typography>

      <Typography
        variant="h2"
        style={{
          fontSize: headingSize,
          marginBottom: "10px",
          marginTop: "20px",
        }}
      >
        Cross-Border Data Transfer
      </Typography>
      <Typography style={{ fontSize: fontSize, lineHeight: "1.6" }}>
        We retain the data within the geography where it is collected. Any data
        transfer across borders is generally not done unless there is a
        compelling reason to do so, like a legal requirement. However, if the
        user visits our website from different geographies and provides data, it
        is still retained in the geography in which it was collected.
      </Typography>

      <Typography
        variant="h2"
        style={{
          fontSize: headingSize,
          marginBottom: "10px",
          marginTop: "20px",
        }}
      >
        Compliance with Privacy Laws
      </Typography>
      <Typography style={{ fontSize: fontSize, lineHeight: "1.6" }}>
        We are compliant with all relevant privacy laws in the geographies we
        operate.
      </Typography>
    </Container>
  );
}

export default PrivacyPolicy;
