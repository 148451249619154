import React from "react";
import { useMediaQuery } from "@mui/material";

const Button = ({ text, onClick, backgroundColor, textColor, fontSize }) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const buttonStyles = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: isMobile ? "12px 30px" : "18px 42px",
    gap: "0.5rem",
    width: isMobile ? "120px" : "188px",
    height: isMobile ? "38px" : "60px",
    backgroundColor: backgroundColor || "#303A99",
    borderRadius: "8px",
    color: textColor || "#ffffff",
    fontFamily: "Figtree",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: fontSize || isMobile ? "10px" : "17px",
    cursor: "pointer",
    border: "none",
    flex: "none",
    order: 1,
    flexGrow: 0,
  };

  return (
    <button style={buttonStyles} onClick={onClick}>
      {text}
    </button>
  );
};

export default Button;

