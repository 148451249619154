import { lineHeight } from "@mui/system";

export const FontStyle = {
  h1: {
    fontSize: "calc(8.75rem/2)",
    fontWeight: 700,
    fontStyle: "Figtree",
    lineHeight: "calc(12.16rem/2)",
  },
  h21: {
    fontSize: "cal( 8rem/2)",
    fontWeight: 600,
    fontStyle: "Figtree",
    lineHeight: "calc( 12rem/2)",
  },
  h22: {
    fontSize: "cal( 8rem/2)",
    fontWeight: 700,
    fontStyle: "Figtree",
    lineHeight: "calc( 12rem/2)",
  },
  h31: {
    fontSize: "calc(7.75rem/2)",
    fontWeight: 600,
    fontStyle: "bold",
    lineHeight: "calc(10.07rem/2)",
    "@media (max-width: 600px)": {
      fontSize: "22px",
      lineHeight: "31px",
    },
  },
  h32: {
    fontSize: "calc(7.75rem/2)",
    fontWeight: 800,
    fontStyle: "Figtree",
    lineHeight: "calc(10.07rem/2)",
  },
  h41: {
    fontSize: "calc(7.5rem/2)",
    fontWeight: 700,
    fontStyle: "Figtree",
    lineHeight: "calc(10.5rem/2)",
    "@media(max-width:600px)": {
      fontSize: "18px",
      lineHeight: "150%",
    },
  },
  h42: {
    fontSize: "calc(7.5rem/2)",
    fontWeight: 700,
    fontStyle: "Figtree",
    lineHeight: "calc(8.4rem/2)",
    textAlign: "center",
    "@media (max-width: 600px)": {
      fontSize: "18px",
      lineHeight: "150%",
    },
  },
  h43: {
    fontSize: "calc(7.5rem/2)",
    fontWeight: 600,
    fontStyle: "Figtree",
    lineHeight: "calc(1.31rem/2)",
    "@media (max-width: 600px)": {
      fontSize: "20px",
    },
  },
  h5: {
    fontSize: "calc(6.75rem/2)",
    fontWeight: 700,
    fontStyle: "Figtree",
    lineHeight: "1calc(0.12rem/2)",
    "@media(max-width:600px)": {
      fontSize: 22,
      lineHeight: "35px",
    },
  },
  sub11: {
    fontSize: "calc(5.25rem/2)",
    fontWeight: 700,
    fontStyle: "Figtree",
    lineHeight: "calc(6.3rem/2)",
    "@media(max-width:600px)": {
      fontSize: "20px",
      lineHeight: "35px",
    },
  },
  sub12: {
    fontSize: "calc(5.25rem/2)",
    fontWeight: 600,
    fontStyle: "Figtree",
    lineHeight: "calc(7.875rem/2)",
    "@media (max-width: 600px)": {
      fontSize: "20px",
      lineHeight: "30px",
    },
  },
  sub13: {
    fontSize: "calc(5.25rem/2)",
    fontWeight: 700,
    fontStyle: "Figtree",
    lineHeight: "calc(7.29rem/2)",
    "@media (max-width: 600px)": {
      fontSize: "18px",
    },
  },
  sub2: {
    fontSize: "calc(5rem/2)",
    fontWeight: 700,
    fontStyle: "Figtree",
    lineHeight: "calc(8.75rem/2)",
    lineHeight: "calc(10.07rem/2)",
    "@media (max-width: 600px)": {
      fontSize: "18px",
      lineHeight: "10%",
    },
  },
  sub3: {
    fontSize: "calc(4.5rem/2)",
    fontWeight: 600,
    fontStyle: "Figtree",
    lineHeight: "calc(6.75rem/2)",
    "@media (max-width: 600px)": {
      fontSize: "16px",
      lineHeight: "150%",
    },
  },
  sub41: {
    fontSize: "calc(4rem/2)",
    fontWeight: 400,
    fontStyle: "Figtree",
    lineHeight: "calc(6.96rem/2)",
  },
  sub42: {
    fontSize: "calc(4rem/2)",
    fontWeight: 600,
    fontStyle: "Figtree",
    lineHeight: "calc(6rem/2)",
    "@media(max-width:600px)": {
      fontSize: "16px",
      lineHeight: "150%",
    },
  },
  sub51: {
    fontSize: "calc(3.75rem/2)",
    fontWeight: 500,
    fontStyle: "Figtree",
    lineHeight: "calc(5.25rem/2)",
  },
  sub52: {
    fontSize: "calc(3.75rem/2)",
    fontWeight: 500,
    fontStyle: "Figtree",
    lineHeight: "calc(6.56rem/2)",
    "@media (max-width: 600px)": {
      fontSize: "12px",
    },
  },
  body11: {
    fontSize: "calc(3.5rem/2)",
    fontWeight: 500,
    fontStyle: "Figtree",
    lineHeight: "6calc(.125rem/2)",
    "@media (max-width: 600px)": {
      fontSize: "12px",
      lineHeight: "21px",
    },
  },
  body12: {
    fontSize: "calc(3.5rem/2)",
    fontWeight: 600,
    fontStyle: "Figtree",
    lineHeight: "6calc(.125rem/2)",
    textAlign: "center",
    "@media(max-width:600px)": {
      fontSize: "18px",
    },
  },
  body13: {
    fontSize: "calc(3.5rem/2)",
    fontWeight: 400,
    fontStyle: "Figtree",
    lineHeight: "calc(6.25rem/2)",
  },
  body14: {
    fontSize: "calc(3.5rem/2)",
    fontWeight: 700,
    fontStyle: "Figtree",
    lineHeight: "6calc(.125rem/2)",
  },
  body21: {
    fontSize: "calc(3.25rem/2)",
    fontWeight: 600,
    fontStyle: "Figtree",
    lineHeight: "calc(5.68rem/2)",
    "@media (max-width: 600px)": {
      fontSize: "14px",
      lineheight: "100%",
    },
  },
  body22: {
    fontSize: "calc(3.25rem/2)",
    fontWeight: 500,
    fontStyle: "Figtree",
    lineHeight: "calc(5.68rem/2)",
    "@media (max-width: 600px)": {
      fontSize: "12px",
      lineHeight: "175%",
    },
  },
  body23: {
    fontSize: "calc(3.25rem/2)",
    fontWeight: 600,
    fontStyle: "Figtree",
    lineHeight: "calc(3.9rem/2)",
    "@media (max-width: 600px)": {
      fontSize: "12px",
      lineHeight: "175%",
    },
  },
  body24: {
    fontSize: "calc(3.25rem/2)",
    fontWeight: 400,
    fontStyle: "Figtree",
    lineHeight: "5calc(.687rem/2)",
  },
  body25: {
    fontSize: "calc(3.25rem/2)",
    fontWeight: 700,
    fontStyle: "Figtree",
    lineHeight: "calc(4.88rem/2)",
  },
  body31: {
    fontSize: "calc(3rem/2)",
    fontWeight: 400,
    fontStyle: "Figtree",
    lineHeight: "calc(5.25rem/2)",
    "@media (max-width: 600px)": {
      fontSize: "12px",
      lineHeight: ".1rem",
    },
  },
  body32: {
    fontSize: "calc(3rem/2)",
    fontWeight: 400,
    fontStyle: "Figtree",
    lineHeight: "calc(5.25rem/2)",
    "@media (max-width: 600px)": {
      fontSize: "12px",
      lineHeight: "175%",
    },
  },
  body41: {
    fontSize: "calc(2.75rem/2)",
    fontWeight: 400,
    fontStyle: "Figtree",
    lineHeight: "calc(4.8rem/2)",
    "@media (max-width: 600px)": {
      fontSize: "12px",
      lineHeight: "175%",
    },
  },
  body42: {
    fontSize: "calc(2.75rem/2)",
    fontWeight: 500,
    fontStyle: "Figtree",
    lineHeight: "calc( 5rem/2)",
  },
  body43: {
    fontSize: "calc(2.75rem/2)",
    fontWeight: 700,
    fontStyle: "Figtree",
    lineHeight: "calc(5.25rem/2)",
    "@media (max-width: 600px)": {
      fontSize: "16px",
    },
  },
  body44: {
    fontSize: "calc(2.75rem/2)",
    fontWeight: 500,
    fontStyle: "Figtree",
    lineHeight: "calc(5.68rem/2)",
  },
  body45: {
    fontSize: "calc(2.75rem/2)",
    fontWeight: 700,
    fontStyle: "Figtree",
    lineHeight: "calc(5.68rem/2)",
    "@media(max-width:600px)": {
      fontSize: "14.1px",
    },
  },
  body51: {
    fontSize: "calc(2.5rem/2)",
    fontWeight: 700,
    fontStyle: "Figtree",
    lineHeight: "calc(4.37rem/2)",
    "@media(max-width:600px)": {
      fontSize: "10px",
      lineHeight: "175%",
    },
  },
  body52: {
    fontSize: "calc(2.5rem/2)",
    fontWeight: 500,
    fontStyle: "Figtree",
    lineHeight: "calc(4.37rem/2)",
    textAlign: "center",
    "@media (max-width: 600px)": {
      fontSize: "12px",
      lineHeight: "175%",
    },
  },
  body53: {
    fontSize: "calc(2.5rem/2)",
    fontWeight: 600,
    fontStyle: "Figtree",
    lineHeight: "calc(3.75rem/2)",
  },
  body54: {
    fontSize: "calc(2.5rem/2)",
    fontWeight: 400,
    fontStyle: "Figtree",
    lineHeight: "calc(4.37rem/2)",
    "@media (max-width: 600px)": {
      fontSize: "12px",
      lineHeight: "175%",
    },
  },
  body55: {
    fontSize: "calc(2.5rem/2)",
    fontWeight: 600,
    fontStyle: "Figtree",
    lineHeight: "calc(3rem/2)",
  },
  body56: {
    fontSize: "calc(2.5rem/2)",
    fontWeight: 300,
    fontStyle: "italic",
    lineHeight: "calc(4.37rem/2)",
    "@media (max-width: 600px)": {
      fontSize: "12px",
      lineHeight: "175%",
    },
  },
  body61: {
    fontSize: "calc(2.25rem/2)",
    fontWeight: 400,
    fontStyle: "Figtree",
    lineHeight: "calc(3.87rem/2)",
    "@media (max-width: 600px)": {
      fontSize: "12px",
      lineHeight: "175%",
    },
  },
  body62: {
    fontSize: "calc(2.25rem/2)",
    fontWeight: 500,
    fontStyle: "Figtree",
    lineHeight: "calc(3.87rem/2)",
    "@media (max-width: 600px)": {
      fontSize: "14px",
      lineHeight: "175%",
    },
  },
};
