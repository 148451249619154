import React from "react";
import { Box, Typography } from "@mui/material";
import { FontStyle } from "../assets/styles";

const Card2 = ({ imageSrc, title, description }) => {
  const styles = {
    container: {
      width: "376px",
      height: "466px",
      backgroundColor: "#FFFFFF",
      borderRadius: "12px",
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
      display: "flex",
      alignSelf: "flex-start",
      flexDirection: "column",
      "@media (max-width: 600px)": {
        width: "90%",
        height: "auto",
        padding: "15px",
      },
    },
    imageContainer: {
      backgroundColor: "#E1E9FF",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "12px",
    },
    image: {
      width: "100%",
      height: "auto",
      borderRadius: "12px",
    },
    textContainer: {
      padding: "25px",
      "@media (max-width: 600px)": {
        padding: "15px",
      },
    },
    title: {
      ...FontStyle.body43,
    },
    description: {
      ...FontStyle.body61,
    },
  };
  return (
    <Box sx={styles.container}>
      <Box sx={styles.imageContainer}>
        <img src={imageSrc} alt="Health Icon" style={styles.image} />
      </Box>
      <Box sx={styles.textContainer}>
        <Typography sx={styles.title}>{title}</Typography>
        <Typography sx={styles.description}>{description}</Typography>
      </Box>
    </Box>
  );
};

export default Card2;
