import { Button } from "@mui/material";
import React from "react";
import { indigo } from "@mui/material/colors";

const CustomButton = ({ label, onClick, disabled }) => (
  <Button
    sx={{
      bgcolor: indigo[900],
      height: 50,
      borderRadius: 2,
      width: "100%",
    }}
    variant="contained"
    size="medium"
    onClick={onClick}
  >
    {label}
  </Button>
);

export default CustomButton;
