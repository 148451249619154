import React from "react";
import { Typography, Box, Stack } from "@mui/material";
import { FontStyle } from "../assets/styles";

const Owner1 = ({ imageSrc, linkedin, title, description, subtitle, href }) => {
    const styles = {
        container: {
            width: { xs: '90vw', sm: '70vw', md: '50vw', lg: '27vw' },
            minWidth: "320px",
            height: { xs: 'auto', sm: 'auto', md: 'auto', lg: "auto" },
            minHeight: { xs: "450px", sm: "500px", md: "600px", lg: "620px" },
            backgroundColor: 'black',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: "10px",
        },
        imageContainer: {
            width: '100%',
            height: { xs: "200px", sm: "240px", md: "17.1vw" },
            borderRadius: "8px",
        },
        image: {
            width: '100%',
            height: '100%',
            objectFit: "cover",
            borderRadius: "8px 8px 0 0",
        },
        textContainer: {
            gap: {  sm: 1.5, md: 1 },
            color: "white",
            paddingX: { xs: 2, sm: 3 },
            textAlign: "center justify",
            marginTop:"10px"
        },
        divider: {
            width: "100%",
            background: '#E6E8EC',
            height: "1px",
        },
        linkedinIcon: {
            height: "42px",
            width: "42px",
            '@media (max-width: 480px)': {
                height: "32px",
                width: "32px",
            },
        },
    };

    return (
        <Stack sx={styles.container}>
            <Stack sx={styles.imageContainer}>
                <img src={imageSrc} alt="Owner" style={styles.image} />
            </Stack>

            <Stack sx={{  }}>
                <Stack sx={styles.textContainer}>
                    <Typography
                        sx={{
                            ...FontStyle.body12,
                            fontSize: { xs: '10px', sm: '12px', lg: '26px' },
                        }}
                    >
                        {title}
                    </Typography>
                    <Typography
                        sx={{
                            ...FontStyle.body45,
                            textAlign: "center",
                            fontSize: { xs: '14px', sm: '16px', lg: '18px' },
                        }}
                    >
                        {subtitle}
                    </Typography>
                    <Typography
                        sx={{
                            ...FontStyle.body61,
                            // textAlign: "justify",
                            textAlign: "center",
                            fontSize: { xs: '12px', sm: '14px', lg: '16px' },
                        }}
                    >
                        {description}
                    </Typography>
                </Stack>

                <Stack paddingX={4} paddingY={1} alignItems={"center"} mt={1} gap={1}>
                    <Box sx={styles.divider} />
                    <a href={href} target="_blank" rel="noopener noreferrer">
                        <img src={linkedin} alt="LinkedIn" style={styles.linkedinIcon} />
                    </a>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default Owner1;
