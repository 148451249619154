import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { FontStyle } from "../assets/styles";

const Card3 = ({ title, description, imageSrc }) => {
  const isMobile = useMediaQuery("(max-width: 600px)");

  const styles = {
    container: {
      width: "100%",
      maxWidth: "486px",
      height: "auto",
      padding: "25px",
      background: "#FCFDFF",
      border: "1px solid #349AFF",
      borderRadius: "12px",
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
      display: "flex",
      flexDirection: "column",
      flex: "none",
      order: 1,
      flexGrow: 0,
      "@media (max-width: 600px)": {
        padding: "15px",
        width: "90%",
      },
    },
    imageContainer: {
      display: "flex",
      marginBottom: "15px",
    },
    image: {
      width: isMobile ? "32px" : "60px",
      height: isMobile ? "32px" : "60px",
    },

    title: {
      ...FontStyle.body21,
    },
    description: {
      ...FontStyle.body41,
    },
  };

  return (
    <Box sx={styles.container}>
      {imageSrc && (
        <Box sx={styles.imageContainer}>
          <img src={imageSrc} alt="Icon" style={styles.image} />
        </Box>
      )}
      <Typography sx={styles.title}>{title}</Typography>
      <Typography sx={styles.description}>{description}</Typography>
    </Box>
  );
};

export default Card3;
