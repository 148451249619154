import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./pages/home";
import About from "./pages/about";
import Product from "./pages/product";
import Contact from "./pages/contact";
import PrivacyPolicy from "./pages/policy";
import PhoneNumberScreen from "./pages/phonenumberscreen";
import OTPVerification from "./pages/validateotp";
import Emergencycall from "./pages/emergencycall";
import Page from "./pages/page";
import { store } from "./redux/store";
import Location from "./pages/location";

const App = () => {
  return (
    <Provider store={store}>
      {/* <ThemeProvider theme={theme}> */}

      <Router>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/product" element={<Product />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/policy" element={<PrivacyPolicy />} />
          <Route path="/otp/:id/:ref_id" element={<OTPVerification />} />
          <Route path="/qrify/:id" element={<PhoneNumberScreen />} />
          <Route path="/call/:id" element={<Emergencycall />} />
        </Routes>
      </Router>
      {/* </ThemeProvider> */}
    </Provider>
  );
};

export default App;
