import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { indigo } from "@mui/material/colors";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { ENDPOINT } from "../configuration/configuration";
import CustomTextInput from "../components/CustomInput";
import LogoBetweenLines from "../components/LogoBetweenLines";
import SProfileHead from "../components/SProfileHeader";

const OTPVerification = () => {
  const { id, ref_id } = useParams();
  const { traceId, phoneNumber, txnId } = useSelector((state) => state.user);
  const [otp, setOtp] = useState("");
  const [isValid, setIsValid] = useState(true);
  const navigate = useNavigate();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const handleVerifyOtp = async () => {
    if (otp.length === 4 && traceId) {
      try {
        const response = await fetch(ENDPOINT.Validation, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            otp: otp,
            phoneNumber: phoneNumber,
            trace_id: traceId,
            ref_id: ref_id,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          setIsValid(true);

          // Navigate to the emergency call screen
          navigate(`/call/${id}`, { replace: true });
        } else {
          setIsValid(false);
          const errorData = await response.json();
          console.error("Error:", errorData);
        }
      } catch (error) {
        setIsValid(false);
        console.error("Error verifying OTP:", error);
      }
    } else {
      setIsValid(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",

        height: "100vh",
        width: "100vw",
        background: "linear-gradient(135deg, #f5f7fa, #c3cfe2)",
      }}
    >
      <Stack
        sx={{
          width: isMobile ? "100%" : isTablet ? "40vh" : 360,
          padding: 2,
          backgroundColor: "white",
          boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
          height: "80%",
          borderRadius: 2,
          marginTop: 2,

          textAlign: "center",
          position: "relative",
        }}
      >
        <Box>
          <LogoBetweenLines logoSource={require("../assets/images/logo.png")} />
          <SProfileHead
            // label1="Hello !"
            label2="SVASTH QRify"
            size1={20}
            size2={20}
            color="#303030"
            lineHeight1={40}
            lineHeight2={40}
          />
        </Box>
        <Stack
          gap={1}
          // justifyContent={"flex-start"}
          alignItems={"flex-start"}
          // border="1px solid black"
          m={2}
        >
          <Typography
            variant="subtitle1"
            sx={{ fontSize: 22, color: "#3a3a3a", fontWeight: "700" }}
          >
            Validate OTP
          </Typography>
          {/* <Typography variant="body1" sx={{ fontSize: 14, color: "#666" }}>
            Please enter the code sent to your mobile phone
          </Typography> */}

          <CustomTextInput
            helperText={isValid ? " " : "Invalid OTP"}
            error={!isValid}
            id="otp-input"
            label="Enter OTP"
            value={otp}
            onChangeText={setOtp}
            inputProps={{ maxLength: 4 }}
          />
          <Button
            sx={{
              bgcolor: indigo[900],
              height: 50,
              borderRadius: 2,
              width: "100%",
            }}
            variant="contained"
            size="medium"
            onClick={handleVerifyOtp}
          >
            Submit
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default OTPVerification;
