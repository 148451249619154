import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setPhoneNumber,
  setTraceId,
  setUser_id,
  settxn_id,
} from "../redux/slice/userSlice";
import SProfileHead from "../components/SProfileHeader";
import CustomButton from "../components/CustomButton";
import CustomTextInput from "../components/CustomInput";
import LogoBetweenLines from "../components/LogoBetweenLines";
import { ENDPOINT } from "../configuration/configuration";
import { Box, Stack, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const PhoneNumberScreen = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [name, setName] = useState(""); // State for name input
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phoneNumber);
  };

  const handlePhoneChange = (text) => {
    if (validatePhoneNumber(text)) {
      setError("");
    } else {
      setError("Please enter a valid 10-digit phone number.");
    }
    setPhone(text);
  };

  const handleNameChange = (text) => {
    setName(text);
  };

  const handleGetOtp = async () => {
    if (!name.trim()) {
      setError("Please enter your name.");
      return;
    }

    if (!validatePhoneNumber(phone)) {
      setError("Please enter a valid 10-digit phone number.");
      return;
    }

    setError("");
    setLoading(true);

    navigator.geolocation.getCurrentPosition(async (position) => {
      const { latitude, longitude } = position.coords;

      const timeoutId = setTimeout(() => {
        setLoading(false);
        alert("Request timed out. Please try again.");
      }, 15000);

      try {
        const response = await fetch(ENDPOINT.Savr(id), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            savr_phone_number: phone,
            savr_name: name,
            latitude: latitude,
            longitude: longitude,
          }), // Sending name along with phone number
        });

        clearTimeout(timeoutId);

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        if (data.status === "success") {
          dispatch(setPhoneNumber(phone));
          dispatch(setTraceId(data.data?.trace_id));
          dispatch(setUser_id(data.data?.ref_id));
          dispatch(settxn_id(data.data?.txnId));
          navigate(`/otp/${id}/${data?.data?.ref_id}`, { replace: true });
        } else {
          alert("Failed to send OTP. Please try again.");
        }
      } catch (error) {
        clearTimeout(timeoutId);
        console.error("Error fetching OTP:", error);
        alert("Failed to send OTP. Please try again.");
      } finally {
        setLoading(false);
      }
    });
  };

  const handlePrivacyPolicyPress = () => {
    window.open("https://svasth.ai/policy", "_blank");
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",

        // height: isMobile
        //   ? "calc(100vh - 120px - 2rem)"
        //   : "calc(100vh - 120px - 2rem)",
        width: "100vw",
        background: "linear-gradient(135deg, #f5f7fa, #c3cfe2)",
      }}
    >
      <Stack
        sx={{
          width: isMobile ? "100%" : isTablet ? "40vh" : 360,
          padding: 2,
          backgroundColor: "white",
          boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
          height: "80%",
          marginTop: 2,
          borderRadius: 2,

          textAlign: "center",
          position: "relative",
        }}
      >
        <LogoBetweenLines logoSource={require("../assets/images/logo.png")} />
        <SProfileHead
          label1="Hello !"
          label2="Welcome to SVASTH QRify"
          size1={isMobile ? 14 : 20}
          size2={isMobile ? 14 : 20}
          color="#303030"
          lineHeight1={isMobile ? 24 : 35}
          lineHeight2={isMobile ? 24 : 35}
        />
        <Stack>
          <CustomTextInput
            label="Name"
            placeholder="Full Name"
            onChangeText={setName} // Handling name input change
            value={name} // Display current name value
            error={error && !name ? "Please enter your name." : ""}
            maxlength={50} // Optional: set max length for the name input
          />
          <CustomTextInput
            label="Phone Number"
            placeholder="enter 10 digit phone number"
            onChangeText={handlePhoneChange}
            value={phone}
            error={error && !validatePhoneNumber(phone) ? error : ""}
            maxlength={10}
          />
          {loading ? (
            <div style={{ textAlign: "center", margin: "20px 0" }}></div>
          ) : (
            <CustomButton
              label="Get Otp"
              onClick={handleGetOtp}
              disabled={loading}
            />
          )}
        </Stack>
        <p
          style={{
            textAlign: "center",
            color: "#888",
            fontSize: "1rem",
            marginTop: "1rem",
          }}
        >
          By continuing you agree to our{" "}
          <span
            style={{
              color: "#0000ff",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={handlePrivacyPolicyPress}
          >
            Terms of Use
          </span>{" "}
          &{" "}
          <span
            style={{
              color: "#0000ff",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={handlePrivacyPolicyPress}
          >
            Privacy Policy
          </span>
          .
        </p>
      </Stack>
    </Box>
  );
};

export default PhoneNumberScreen;
