import React from "react";

const SProfileHead = ({
  label1,
  label2,
  size1,
  size2,
  color,
  lineHeight1,
  lineHeight2,
}) => (
  <div style={{ textAlign: "center" }}>
    {label1 && (
      <h1
        style={{
          fontSize: `${size1}px`,
          color,
          lineHeight: `${lineHeight1}px`,
        }}
      >
        {label1}
      </h1>
    )}
    {label2 && (
      <h2
        style={{
          fontSize: `${size2}px`,
          color,
          lineHeight: `${lineHeight2}px`,
        }}
      >
        {label2}
      </h2>
    )}
  </div>
);

export default SProfileHead;
