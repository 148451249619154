import { Box, Stack } from "@mui/material";
import React from "react";
import AlertChip from "../components/AlertChip";
import star from "../assets/images/star.png";
import Button from "../components/Button";
import Phone from "../assets/images/phone.png";
import ImageComponent from "../components/Images";
import Vision from "../assets/images/vision.png";
import Mission from "../assets/images/mission.png";
import Our from "../components/Our";
import Our1 from "../components/Our1";
import Owner1 from "../components/owner";
import ab from "../assets/images/ab.jpg";
import Samy from "../assets/images/samy1.jpeg";
import Ravi from "../assets/images/ravi1.jpeg";
import Footer from "../components/Footer";
import Layout from "../components/Layout";
import TextColor from "../components/TextColor";
import { FontStyle } from "../assets/styles";
import NavBar from "../components/NavBar";
import Header from "../components/Navbarmobile";
import Svasthlogo from "../assets/images/Svasthlogo.png";
import { useNavigate } from "react-router-dom";
import In from "../assets/images/in.png";

const About = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/product");
  };
  return (
    <Stack
      display={"flex"}
      // width={"100%"}
      sx={{ position: "relative", gap: 8 }}
      justifyContent={"center"}
      alignItems={"center"}
    >
      {/* <NavBar /> */}
      <Layout>
        <NavBar logo={Svasthlogo} logoHeight="80px" logoWidth="205px" />
        <Header
          logoImage={Svasthlogo}
          style={{
            height: "34px",
            width: "86.71px",
          }}
        />
        <Box
          display={"flex"}
          flexWrap="wrap"
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ "@media(max-width:600px)": { gap: 3 } }}
        >
          <Stack
            width={{ xs: "100%", sm: "65%" }}
            gap={8}
            sx={{
              "@media(max-width:600px)": { alignItems: "center", gap: 0.5 },
            }}
          >
            <AlertChip
              textStyle={FontStyle.sub51}
              imageSrc={star}
              style={{  left: "86%", }}
              label={"Your Well-Being, Our Priority!"}
            />
            <Stack
              sx={{
                gap: 3,
                "@media (max-width: 600px)": {
                  padding: "1px",
                  textAlign: "center",
                  gap: 1,
                  pb: 4,
                },
              }}
            >
              <Stack>
                <TextColor
                  text="Revolutionising Health with Intelligent, Data-Driven Care. "
                  highlightWords={["Intelligent"]}
                  highlightColor="#008000"
                  defaultColor="#000000"
                  styleType="h31"
                  sx={
                    {
                      // fontSize: '32px',
                    }
                  }
                />

              </Stack>
              <TextColor
                text="Empowering Health, Transforming Lives. Innovating Care"
                highlightWords={["Transforming", "Lives", "."]}
                highlightColor="#f44336"
                defaultColor="#000000"
                styleType="body11"
              />
            </Stack>
              <Button text="Learn More" onClick={handleClick}  />
           
          </Stack>
          <Stack
            display={"flex"}
            flex={1}
            sx={{ justifyContent: "flex-start" }}
          >
            <Stack
              sx={{
                height: "41.3vw",
                width: "28.5vw",
                p: 2,
                minHeight: "432px",
                minWidth: "320px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageComponent
                Image={Phone}
                style={{ width: "100%", height: "100%" ,alignItems:"center"}}
              />
            </Stack>
          </Stack>
        </Box>
      </Layout>
      <Layout>
        <Box display={"flex"} justifyContent={"center"}>
          <Box
            display={"flex"}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              gap: 3,
              "@media(max-width:600px)": {
                gap: 0,
              },
            }}
          >
            <Box sx={{}}>
              <TextColor
                text="About Svasth"
                highlightWords={["Svasth"]}
                highlightColor="#3f51b5"
                defaultColor="#000000"
                styleType="h41"
              />
            </Box>
            <Box sx={{}}>
              <TextColor
                text="Svasth leads the way in healthcare innovation, offering services that
            redefine health management. From advanced outpatient and inpatient
            care to comprehensive wellness programs and the unique emergency
            response system - QRify, we ensure that every aspect of your health is
            meticulously covered. Our technology-driven solutions collect
            real-time health data, analysed by sophisticated algorithms to assess
            risks and recommend intervention procedures. This approach enables
            precise diagnosis for over nine critical illnesses, providing
            personalised treatment plans and expert doctor recommendations. We
            also collaborate with insurance companies to streamline portfolio
            management and reduce costs, ensuring immediate and effective care. At
            Svasth, we’re not just managing health—we’re revolutionising it with
            technology that truly makes a difference."
                highlightWords={[]}
                highlightColor="#3f51b5"
                defaultColor="#000000"
                styleType="body31"
              />
            </Box>
          </Box>
        </Box>
      </Layout>
      <Layout backgroundColor={"#000000"}>
        <Box
          display="flex"
          sx={{
            width: "100%",
            paddingTop: 5.5,
            paddingBottom: 5,
            color: "#ffffff",
            gap: 17,
            flexDirection: { xs: "column", md: "row" }, // Stack vertically on mobile (xs), horizontally on laptop (md+)
            justifyContent: "center",
            alignItems: "center",
            // flexWrap: "wrap",
            "@media(max-width:600px)": {
              gap: 5,
            },
          }}
        >
          {/* Vision Section */}
          <Our
            image={Vision}
            title="OUR VISION"
            subtitle="Empowering Health Through Intelligent Management Solutions."
            description="Our vision is to build a sustainable healthcare ecosystem where everyone benefits from proactive, data-driven healthcare management, ensuring a healthier, more connected world where every individual's well-being is prioritised."
          />

          {/* Mission Section */}
          <Our1
            image={Mission}
            title="OUR MISSION"
            subtitle="Revolutionising Healthcare with Advanced Technology and Intelligent Solutions."
            description="We are dedicated to transforming healthcare by leveraging cutting-edge technology and real-time data to provide comprehensive, personalised care. Our mission is to enhance overall health management, offering tailored solutions that promote well-being and ensure optimal outcomes for every individual."
          />
        </Box>
      </Layout>
      <Layout>
        <Stack
          display={"flex"}
          sx={{
            justifyContent: "center",
            "@media(max-width:600px)": { gap: 1 },
          }}
          gap={9}
        >
          {/* <Box display={"flex"} flexDirection={"column"} sx={{ width: "89.5%", gap: 9.8, height: "81.2%", }}> */}
          <Box
            display={"flex"}
            sx={{
              justifyContent: "center",
            }}
          >
            <TextColor
              text="Meet the Minds Behind Svasth"
              highlightWords={["Svasth"]}
              highlightColor="#3f51b5"
              defaultColor="#000000"
              styleType="h42"
            />
          </Box>
          <Box
            // display={"flex"}
            sx={
              {
                // width: "89.5%",
                // height: "67.5%",
                // background: "blue",
              }
            }
          >
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: "100%",
                gap: { xs: 4, sm: 8},
                flexDirection: { xs: "column", sm: "row" }, // Vertical on mobile (xs), horizontal on laptop (sm)
              }}
            >
              <Owner1
                href={"https://www.linkedin.com/in/raveendran-iyengar-0a01727/"}
                linkedin={In}
                imageSrc={Ravi}
                title="Ravi"
                subtitle="Dr. Raveendran - Executive Chairman"
                description="Our risk management maestro, Doc, expertly navigates complex challenges. From managing high-profile claims—including one of India's largest Tsunami-related cases to crafting bespoke medical insurance for special children, Doc is our ultimate problem-solver."
              />
              <Owner1
                href={"https://www.linkedin.com/in/saumyadeep-datta-01197223/"}
                linkedin={In}
                imageSrc={Samy}
                title="Samy"
                subtitle="Saumyadeep Datta - Managing Director"
                description="Samy is our business dynamo, renowned for building & leading successful teams. He turned worksite marketing into a digital triumph and excels in creating new channels and verticals. With his sharp focus and relentless drive, Samy is always set to conquer the next challenge."
              />
              
            </Box>
          </Box>
        </Stack>
      </Layout>
      <Footer />
    </Stack>
  );
};

export default About;
