import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Card,
  Fab,
  Avatar,
  Stack,
  Divider,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import CallIcon from "@mui/icons-material/Call";
import axios from "axios";
import { ENDPOINT } from "../configuration/configuration";
import { useSelector } from "react-redux";
import LogoBetweenLines from "../components/LogoBetweenLines";
import SProfileHead from "../components/SProfileHeader";
import { useTheme } from "@mui/material/styles";

const Emergencycall = () => {
  const [userName, setUserName] = useState("");
  const [userImg, setUserImg] = useState("");
  const [loading, setLoading] = useState(false);
  const [thankYouMessage, setThankYouMessage] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const { txnId } = useSelector((state) => state.user);

  const [ref_id, setId] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(ENDPOINT.GetDetails(id));
        setUserName(response.data.first_name);
        setId(response.data._id);
        setUserImg(
          `data:${response.data.image.content_type};base64,${btoa(
            response.data.image.image_data
          )}`
        );
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const handleCallClick = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        ENDPOINT.Emergency_Call(ref_id, userName, txnId),
        {
          action: "emergency_call",
          user: userName,
        }
      );

      setThankYouMessage("Thank you for reaching out. Help is on the way!");
    } catch (error) {
      console.error("Error making emergency call:", error);
      setThankYouMessage("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",

        height: "100vh",
        width: "100vw",
        background: "linear-gradient(135deg, #f5f7fa, #c3cfe2)",

        // border: "2px solid black",
      }}
    >
      <Stack
        justifyContent={"flex-start"}
        alignItems={"center"}
        sx={{
          width: isMobile ? "100%" : isTablet ? "40vh" : 360,
          padding: 2,
          backgroundColor: "white",
          boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
          height: "80%",
          marginTop: 2,
          borderRadius: 2,

          textAlign: "center",
          position: "relative",
        }}
      >
        <LogoBetweenLines logoSource={require("../assets/images/logo.png")} />

        <SProfileHead
          label1="Emergency Assistance !"
          size1={isMobile ? 14 : 20}
          color="#A61414"
          lineHeight1={isMobile ? 24 : 35}
          lineHeight2={isMobile ? 24 : 35}
        />

        <Box
          display={"flex"}
          sx={{
            justifyContent: "flex-start",
            alignItems: "center",
            margin: isMobile ? "0.5rem" : 2,
            marginLeft: isMobile ? "0.5rem" : 12,
            marginRight: isMobile ? "0.5rem" : 4,
            gap: 1,
            width: "100%",
          }}
        >
          <Avatar
            alt="Profile"
            src={userImg === "" ? "/static/images/avatar/1.jpg" : userImg}
            sx={{
              width: isMobile ? 40 : 80,
              height: isMobile ? 40 : 80,
            }}
          />
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Typography
              variant="subtitle1"
              sx={{
                color: "#2c3e50",
                fontSize: isMobile ? "0.75rem" : "1rem",
                fontWeight: 500,
              }}
            >
              Help Help !
            </Typography>
            <Typography
              variant="h5"
              sx={{
                color: "#34495e",
                fontWeight: 700,
                fontSize: isMobile ? "1rem" : "2rem",
              }}
            >
              {userName}
            </Typography>
          </Stack>
        </Box>
        <Divider />
        <Stack
          position={"absolute"}
          bottom={isMobile ? 108 : 36}
          left={0}
          width="100%"
        >
          <Stack
            gap={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <Fab
                color="primary"
                sx={{
                  backgroundColor: "#28a745",
                  width: isMobile ? 48 : 64,
                  height: isMobile ? 48 : 64,
                }}
                onClick={handleCallClick}
              >
                <CallIcon />
              </Fab>
            )}
            <Typography
              variant="body2"
              sx={{
                fontWeight: 700,
                color: "#34495e",
                fontSize: isMobile ? "0.875rem" : "1rem",
              }}
            >
              {thankYouMessage ? thankYouMessage : "Call Emergency"}
            </Typography>
          </Stack>

          <Stack
            sx={{
              textAlign: "center",
              fontWeight: 700,
              fontSize: isMobile ? 14 : 16,
              gap: 1,
              color: "#34495e",
            }}
          >
            <Typography variant="body2" sx={{ color: "#e74c3c" }}>
              svasth.ai
            </Typography>
            <Typography variant="body2" sx={{ color: "#8e44ad" }}>
              24/7 Available
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Emergencycall;
