import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import { FontStyle } from "../assets/styles";

const Card5 = ({
  title,
  description,
  heading,
  background,
  textcolor,
  height,
  width,
}) => {
  const isMobile = useMediaQuery("(max-width: 600px)");

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      width: isMobile ? "100%" : width ? width : "450px",
      height: isMobile ? "auto" : height ? height : "260px",
      padding: isMobile ? "20px" : "40px",
      background: background
        ? background
        : "linear-gradient(to right, #1e1e1e, #0a192f)",
      border: "1px solid #ffffff",
      borderRadius: "12px",
      color: textcolor ? textcolor : "#ffffff",
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
      marginBottom: isMobile ? "16px" : "0",
      "@media (max-width: 600px)": {
        gap: 1,
      },
    },
    heading: {
      ...FontStyle.body51,
    },
    title: {
      ...FontStyle.sub3,
    },
    description: {
      ...FontStyle.body32,
    },
  };

  return (
    <Box sx={styles.container} gap={3}>
      <Box sx={styles.heading}>{heading}</Box>
      <Box sx={styles.title}>{title}</Box>
      <Box sx={styles.description}>{description}</Box>
    </Box>
  );
};

export default Card5;

// import React from "react";
// import { Box } from "@mui/material";
// import { FontStyle } from '../assets/styles';

// const Card4 = ({ title, description, heading, background, textcolor,height,width}) => {
//   const styles = {
//     container: {
//       display: "flex",
//       flexDirection: "column",
//       alignItems: "flex-start",
//       justifyContent: "center",
//       width:width ? width: "450px",
//       height:height ? height: "260px",
//       padding: "40px",
//       background: background
//         ? background
//         : "linear-gradient(to right, #1e1e1e, #0a192f)",
//       border: "1px solid #ffffff",
//       borderRadius: "12px",
//       color: textcolor ? textcolor : "#ffffff",
//       boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
//     },
//     heading: {
//       ...FontStyle.body51,
//     },
//     title: {
//       ...FontStyle.sub3,
//     },
//     description: {
//       ...FontStyle.body3,
//     },
//   };

//   return (
//     <Box sx={styles.container} gap={3}>
//       <Box sx={styles.heading}>{heading}</Box>
//       <Box sx={styles.title}>{title}</Box>
//       <Box sx={styles.description}>{description}</Box>
//     </Box>
//   );
// };

// export default Card4;
